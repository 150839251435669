<template>
  <div class="yt-content">
    <div class="filter">
      <div>
        <span>考试筛选:</span>
        <el-dropdown @command="selectExam" placement="bottom-start">
          <span class="select">{{ examName }}<i class="el-icon-arrow-down el-icon--right"></i></span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(option, index) in examOptions"
              :key="index"
              :command="option.examId"
              :class="{
                active: (option.examId === 'all' && examId === '') || option.examId === examId
              }"
            >
              {{ option.examName }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div style="display: flex; align-items: center">
        <span style="margin-right: 15px">人数: {{ departmentInfo.totalNum }}</span>
        <template v-if="departmentInfo.hasOwnProperty('leaderNames')">
          <el-tooltip effect="dark" :content="departmentInfo.leaderNames.toString()" placement="top-end">
            <div class="leader">负责人: {{ departmentInfo.leaderNames.toString() }}</div>
          </el-tooltip>
        </template>
        <el-button size="small" type="primary" style="width: 50px;margin-right: 15px" @click="exportReport">导出</el-button>
      </div>
    </div>
    <el-table :data="reportList" v-loading="reportLoading" element-loading-text="加载中" class="yt-table" height="calc(100% - 107px)">
      <el-table-column label="考试名称" prop="name" show-overflow-tooltip />
      <el-table-column label="考试日期" show-overflow-tooltip>
        <template slot-scope="scope">{{ $formatTime(scope.row.startTime, 'yyyy-MM-dd') }}</template>
      </el-table-column>
      <el-table-column label="参考人数" prop="userCount" show-overflow-tooltip />
      <el-table-column label="考试总分" prop="score" show-overflow-tooltip />
      <el-table-column label="合格分数线" show-overflow-tooltip>
        <template slot-scope="scope">
          <span style="color: #FFC117">{{ scope.row.passScore }}</span>
        </template>
      </el-table-column>
      <el-table-column label="合格人数" prop="passer" show-overflow-tooltip />
      <el-table-column label="班级最高分" show-overflow-tooltip>
        <template slot-scope="scope">
          <span style="color: #FF5050">{{ scope.row.highestScore }}</span>
        </template>
      </el-table-column>
      <el-table-column label="班级最低分" prop="lowestScore" show-overflow-tooltip />
      <el-table-column label="平均分" prop="averageScore" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ Math.round(scope.row.averageScore * 100) / 100 }}</span>
        </template>
      </el-table-column>
    </el-table>
    <Page
      class="yt-page"
      :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
      :total="total"
      :current="pageNum + 1"
      :page-size-opts="[10, 20, 40, 100]"
      :page-size="pageSize"
      @on-change="changePage"
      @on-page-size-change="changePageSize"
      show-elevator
      show-sizer
      show-total
    />
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import statisticApi from '@api/statistic'
import departmentApi from '@api/department'
export default {
  name: 'DepartmentExamTable',
  components: {
    YTIcon
  },
  data() {
    return {
      departmentInfo: {},
      departmentId: '',
      examId: '',
      examName: '全部',
      examOptions: [{ examId: 'all', examName: '全部' }],
      reportList: [],
      reportLoading: false,
      total: 0,
      pageNum: 0,
      pageSize: 10
    }
  },
  methods: {
    getDepartmentInfo(departmentId) {
      departmentApi.getDepartmentInfo(departmentId).then(res => {
        this.departmentInfo = res.res
        this.departmentId = departmentId
        this.getDepartmentExam()
        this.searchReportList()
      })
    },
    getDepartmentExam() {
      this.examOptions = [{ examId: 'all', examName: '全部' }]
      statisticApi.getDepartmentExam(this.departmentId).then(res => {
        this.examOptions = this.examOptions.concat(res.res)
      })
    },
    searchReportList() {
      let payload = {
        departmentId: this.departmentId,
        examId: this.examId
      }
      this.reportLoading = true
      statisticApi
        .getDepartmentExamInfo(payload, this.pageNum, this.pageSize)
        .then(res => {
          this.reportList = res.res.data
          this.total = res.res.total
        })
        .finally(() => {
          this.reportLoading = false
        })
    },
    reSearchReportList(departmentId) {
      this.pageNum = 0
      this.examId = ''
      this.getDepartmentInfo(departmentId)
    },
    selectExam(examId) {
      if (examId === 'all') {
        this.examId = ''
        this.examName = '全部'
      } else {
        this.examId = examId
        this.examOptions.map(item => {
          if (item.examId === examId) {
            this.examName = item.examName
          }
        })
      }
      this.pageNum = 0
      this.searchReportList()
    },
    changePage(pageNum) {
      this.pageNum = pageNum - 1
      this.searchReportList()
    },
    changePageSize(pageSize) {
      this.pageSize = pageSize
      this.searchReportList()
    },
    exportReport() {
      let payload = {
        departmentId: this.departmentId,
        examId: this.examId
      }
      statisticApi.exportDepartmentExam(payload).then(res => {
        this.$downloadFlow(res, this.departmentInfo.departmentName, 'xls')
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/filterDropdownMenu';
.filter {
  justify-content: space-between !important;
}
</style>
