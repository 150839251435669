<template>
  <div class="yt-content">
    <div class="filter">
      <div class="filter">
        <div>
          <span>人员筛选:</span>
          <el-dropdown placement="bottom-start">
            <span class="select"
              >{{ staffSelection.length > 0 ? `已选${staffSelection.length}人` : '全部' }}<i class="el-icon-arrow-down el-icon--right"></i
            ></span>
            <el-dropdown-menu slot="dropdown">
              <el-input v-model="staffKeyword" class="yt-search search" placeholder="通过人员名称筛选">
                <i slot="suffix" class="el-input__icon el-icon-search" @click="toFilterNode"></i>
              </el-input>
              <div class="list-container">
                <el-tree
                  :data="staffData"
                  :props="staffProps"
                  node-key="userId"
                  ref="staff-tree"
                  :filter-node-method="filterNode"
                  show-checkbox
                  check-strictly
                >
                </el-tree>
              </div>
              <div class="footer">
                <el-button type="primary" @click="selectUser">确定</el-button>
                <el-button size="small" @click="resetSelection">
                  <div>
                    <YTIcon :href="'#icon-zhongzhi'"></YTIcon>
                    <p>重置</p>
                  </div>
                </el-button>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div>
          <span>考试筛选:</span>
          <el-dropdown @command="selectExam" placement="bottom-start">
            <span class="select">{{ examName }}<i class="el-icon-arrow-down el-icon--right"></i></span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(option, index) in examOptions"
                :key="index"
                :command="option.examId"
                :class="{
                  active: (option.examId === 'all' && examId === '') || option.examId === examId
                }"
              >
                {{ option.examName }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div style="display: flex; align-items: center">
        <span style="margin-right: 15px">人数: {{ departmentInfo.totalNum }}</span>
        <template v-if="departmentInfo.hasOwnProperty('leaderNames')">
          <el-tooltip effect="dark" :content="departmentInfo.leaderNames.toString()" placement="top-end">
            <div class="leader">负责人: {{ departmentInfo.leaderNames.toString() }}</div>
          </el-tooltip>
        </template>
        <el-button size="small" type="primary" style="width: 50px;margin-right: 15px" @click="exportReport">导出</el-button>
      </div>
    </div>
    <el-table
      ref="reportTable"
      :data="reportList"
      v-loading="reportLoading"
      element-loading-text="加载中"
      class="yt-table"
      height="calc(100% - 107px)"
    >
      <el-table-column type="expand" width="20px">
        <template slot-scope="props">
          <p>考试信息</p>
          <el-table :data="props.row.examUserVOList" class="yt-table" @sort-change="sortSearchReportList">
            <el-table-column label="考试名称" prop="name" show-overflow-tooltip />
            <el-table-column label="考试日期" prop="name" show-overflow-tooltip>
              <template slot-scope="scope">{{ $formatTime(scope.row.examStartTime, 'yyyy-MM-dd') }}</template>
            </el-table-column>
            <el-table-column label="参考人数" prop="userCount" show-overflow-tooltip />
            <el-table-column label="排名" prop="examRank" :sortable="examId === '' ? false : 'custom'" show-overflow-tooltip />
            <el-table-column label="正确率" show-overflow-tooltip>
              <template slot-scope="scope" v-if="scope.row.hasOwnProperty('accuracy')">{{
                Math.round(scope.row.accuracy * 10000) / 100 + '%'
              }}</template>
            </el-table-column>
            <el-table-column label="总分" prop="examScore" show-overflow-tooltip />
            <el-table-column label="得分" prop="score" show-overflow-tooltip />
            <el-table-column label="平均分" prop="avgScore" show-overflow-tooltip>
              <template slot-scope="scope">
                <span>{{ Math.round(scope.row.avgScore * 100) / 100 }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="80px">
              <template slot-scope="{ row, index }">
                <span class="tool-button" @click="downloadExamReport(props.row, row)">下载报告</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column label="个人信息" show-overflow-tooltip>
        <el-table-column label="人员名称" prop="userName" show-overflow-tooltip />
        <el-table-column label="学号" prop="jobNumber" label-class-name="has-border" show-overflow-tooltip />
      </el-table-column>
      <el-table-column label="总体考试信息" show-overflow-tooltip>
        <el-table-column label="参考次数" prop="referenceNumber" show-overflow-tooltip />
        <el-table-column label="历史最高排名" prop="lowestRank" show-overflow-tooltip />
        <el-table-column label="历史最低排名" prop="highestRank" show-overflow-tooltip />
      </el-table-column>
    </el-table>
    <Page
      class="yt-page"
      :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
      :total="total"
      :current="pageNum + 1"
      :page-size-opts="[10, 20, 40, 100]"
      :page-size="pageSize"
      @on-change="changePage"
      @on-page-size-change="changePageSize"
      show-elevator
      show-sizer
      show-total
    />
  </div>
</template>

<script>
import examApi from '@api/exam'
import YTIcon from '@components/common/YTIcon'
import statisticApi from '@api/statistic'
import examReportApi from '@/api/examReport'
import departmentApi from '@/api/department'
export default {
  name: 'StaffExamTable',
  components: {
    YTIcon
  },
  data() {
    return {
      departmentInfo: {},
      departmentId: '',
      staffKeyword: '',
      staffData: [],
      staffProps: {
        label: 'userName'
      },
      staffSelection: [],
      examId: '',
      examName: '全部',
      examOptions: [{ examId: 'all', examName: '全部' }],
      sort: '',
      reportList: [],
      reportLoading: false,
      total: 0,
      pageNum: 0,
      pageSize: 10
    }
  },
  methods: {
    getDepartmentInfo(departmentId) {
      departmentApi.getDepartmentInfo(departmentId).then(res => {
        this.departmentInfo = res.res
        this.departmentId = departmentId
        this.getDepartmentExam()
        this.searchReportList()
        this.getDepartmentUser()
      })
    },
    getDepartmentExam() {
      this.examOptions = [{ examId: 'all', examName: '全部' }]
      statisticApi.getDepartmentExam(this.departmentId).then(res => {
        this.examOptions = this.examOptions.concat(res.res)
      })
    },
    getDepartmentUser() {
      statisticApi.getDepartmentExamUser(this.departmentId).then(res => {
        this.staffData = res.res
      })
    },
    searchReportList() {
      let payload = {
        departmentId: this.departmentId,
        examId: this.examId,
        userIds: this.staffSelection,
        sort: this.sort
      }
      this.reportLoading = true
      statisticApi
        .getDepartmentUserExam(payload, this.pageNum, this.pageSize)
        .then(res => {
          this.reportList = res.res.data
          this.total = res.res.total
        })
        .finally(() => {
          this.reportLoading = false
        })
    },
    reSearchReportList(departmentId) {
      this.pageNum = 0
      this.examId = ''
      this.staffSelection = []
      this.getDepartmentInfo(departmentId)
    },
    sortSearchReportList({ column, prop, order }) {
      this.sort = order === null ? '' : 'examRank,' + (order === 'ascending' ? 'asc' : 'desc')
      this.pageNum = 0
      this.searchReportList()
    },
    toFilterNode() {
      this.$refs['staff-tree'].filter(this.staffKeyword)
      this.$refs['staff-tree'].setCheckedNodes([])
    },
    filterNode(value, data) {
      //知识点树过滤
      if (!value) return true
      return data.userName.indexOf(value) !== -1
    },
    resetSelection() {
      this.$refs['staff-tree'].setCheckedNodes([])
    },
    selectUser() {
      this.staffSelection = this.$refs['staff-tree'].getCheckedKeys()
      this.pageNum = 0
      this.searchReportList()
    },
    selectExam(examId) {
      if (examId === 'all') {
        this.examId = ''
        this.examName = '全部'
      } else {
        this.examId = examId
        this.examOptions.map(item => {
          if (item.examId === examId) {
            this.examName = item.examName
          }
        })
      }
      this.pageNum = 0
      this.searchReportList()
    },
    downloadExamReport(row, examData) {
      examReportApi.exportSingleReport(examData.examId, row.userId).then(res => {
        if (res.res !== '') {
          this.$download(res.res)
        } else {
          this.$message.error('暂无可下载报告')
        }
      })
    },
    changePage(pageNum) {
      this.pageNum = pageNum - 1
      this.searchReportList()
    },
    changePageSize(pageSize) {
      this.pageSize = pageSize
      this.searchReportList()
    },
    exportReport() {
      let payload = {
        departmentId: this.departmentId,
        examId: this.examId,
        userIds: this.staffSelection
      }
      statisticApi.exportDepartmentUserExam(payload).then(res => {
        this.$downloadFlow(res, this.departmentInfo.departmentName, 'xls')
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/filterDropdownMenu';
::v-deep .is-group {
  th:nth-child(3) {
    border-right: none;
  }
}
.filter {
  justify-content: space-between !important;
}
</style>
